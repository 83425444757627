import React from 'react';
import { Box, Heading, Text, Stack, Link } from "@chakra-ui/react";
import BackgroundImage from '../../../images/backgrounds/contact-BR.svg';
import { Link as GatsbyLink } from 'gatsby';

export default function HelpPlaceholderSection() {
  return <Box backgroundImage={`url(${BackgroundImage})`} backgroundPosition="bottom right" backgroundSize={['90%', null, '60%', '60%']} backgroundRepeat="no-repeat" py={150} minHeight="100vh">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box pb={['8rem']}>
        <Stack spacing={8}>
          <Heading size="2xl">
            Need help?
          </Heading>
          <Text>We're currently working on our help center, but in the meantime we'd be more than happy to help you directly.<br/>Email us:</Text>
          <Link href="mailto:kiaora@makeripples.nz" as={GatsbyLink} isExternal><Heading size="2xl" color="brand.primary">kiaora@makeripples.nz</Heading></Link>
        </Stack>
      </Box>
    </Box>
  </Box>
}