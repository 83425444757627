import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import HelpPlaceholderSection from "../containers/sections/help/help-placeholder-section";
import MakeRipplesSection from "../containers/sections/make-ripples-section";
import OtherPagesSection from "../containers/sections/other-pages-section";

const Help = ({ location }) => {
  return <Layout activePage={location.pathname}>
    <Helmet>
      <title>makeripples - Help</title>
    </Helmet>
    <HelpPlaceholderSection />
    <OtherPagesSection activePage={location.pathname} />
    <MakeRipplesSection />
  </Layout>
}

export default Help;